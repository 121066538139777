.starsContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
  overflow: hidden;
}

.shootingStar {
  position: absolute;
  width: 150px;
  height: 2px;
  background: linear-gradient(90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(240, 240, 255, 0.1) 100%);
  border-radius: 50%;
  filter: drop-shadow(0 0 6px rgba(180, 180, 255, 0.8));
  animation: shootingStarAnimation 1s ease-out forwards;
  opacity: 1;
  transform-origin: center;
  transform: rotate(90deg);
}

.shootingStar::before {
  content: '';
  position: absolute;
  top: -2px;
  right: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px 2px rgba(180, 180, 255, 0.9);
}

@keyframes shootingStarAnimation {
  0% {
    transform: translateX(0) translateY(0) scale(0.1) rotate(var(--rotation));
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  100% {
    transform: translateX(var(--moveX)) translateY(var(--moveY)) scale(1) rotate(var(--rotation));
    opacity: 0;
  }
}

/* Add a variation for different stars */
.shootingStar:nth-child(2n) {
  animation-duration: 1.2s;
}

.shootingStar:nth-child(3n) {
  animation-duration: 0.8s;
}

.shootingStar:nth-child(4n) {
  width: 200px;
}

.shootingStar:nth-child(5n) {
  width: 120px;
}