.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  padding-top: 30px;
}

.contentWrapper {
  background: linear-gradient(45deg, rgba(40, 30, 100, 0.8), rgba(80, 60, 150, 0.7));
  padding: 24px 70px 24px 24px;
  color: var(--mantine-color-white);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  backdrop-filter: blur(4px);
  border-radius: 16px;
  min-height: 420px;
  width: 100%;
  box-shadow: 0 0 30px rgba(150, 120, 255, 0.4);
  border: 1px solid rgba(180, 160, 255, 0.2);
  touch-action: pan-y pinch-zoom;
  position: relative;
  overflow: hidden;
}

/* Add this for the constellation effect */
.contentWrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image:
    radial-gradient(circle at 10% 20%, rgba(255, 255, 255, 0.3) 1px, transparent 1px),
    radial-gradient(circle at 30% 65%, rgba(255, 255, 255, 0.25) 1px, transparent 1px),
    radial-gradient(circle at 60% 10%, rgba(255, 255, 255, 0.2) 1px, transparent 1px),
    radial-gradient(circle at 80% 40%, rgba(255, 255, 255, 0.3) 1px, transparent 1px),
    radial-gradient(circle at 90% 90%, rgba(255, 255, 255, 0.25) 1px, transparent 1px);
  background-size: 300px 300px;
  opacity: 0.3;
  z-index: 0;
  pointer-events: none;
}

.contentWrapper a {
  color: var(--mantine-color-white);
}

.date {
  font-weight: 700;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0.9;
  background: rgba(80, 60, 150, 0.5);
  padding: 5px 10px;
  border-radius: 4px;
  display: inline-block;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.description {
  font-weight: 500;
  line-height: 1.8;
  font-size: 1.1rem;
  letter-spacing: 0.4px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  padding: 16px;
  margin-top: 10px;
  background: rgba(40, 30, 100, 0.7);
  border: 1px solid rgba(180, 160, 255, 0.2);
  transition: all 0.3s ease;
  animation: cosmicEntrance 0.8s ease-out;
  position: relative;
  z-index: 1;
  color: rgba(255, 255, 255, 0.95);
}

.description a {
  color: var(--mantine-color-white);
  text-decoration: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
  transition: border-color 0.2s ease;
  padding-bottom: 2px;
}

.description a:hover {
  border-color: var(--mantine-color-white);
}

.durationGroup {
  margin-bottom: 20px;
  justify-content: space-between;
}

.mdcalcLogo {
  max-width: 75px;
  border-radius: 6px;
}

.panel {
  padding: 10px 0px 20px 20px;
  animation: floatAnimation 6s infinite ease-in-out;
}

.subtitle {
  text-transform: unset;
  font-size: 1rem;
  font-weight: 400;
  color: var(--mantine-color-white);
  text-shadow: 0 0 15px rgba(150, 120, 255, 0.7);
}

.title {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700;
  color: var(--mantine-color-white);
  letter-spacing: 1px;
  line-height: 1rem;
  text-shadow: 0 0 15px rgba(150, 120, 255, 0.7);
}

.titleGroup {
  margin-bottom: 10px;
}

.role {
  margin-bottom: 1rem;
}

.tab {
  height: 90px;
  width: 200px;
  transition: all 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  border-radius: 12px;
  margin-bottom: 12px;
  background: rgba(80, 60, 150, 0.2);
  border: 1px solid rgba(180, 160, 255, 0.2);
  position: relative;
  overflow: hidden;
  justify-content: center;
}

.tab::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 1%, transparent 10%);
  background-size: 15px 15px;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.tabs :global(.mantine-Tabs-tabLabel) {
  font-size: 20px;
  font-weight: 600;
  transition: color 0.2s ease;
}

.tabs :global(.mantine-Tabs-list) {
  padding-right: 28px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.tabs :global(.mantine-Tabs-panel) {
  padding: 20px 30px;
  transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  animation: fadeIn 0.5s ease-out;
  position: relative;
}

.tabs :global(.mantine-Tabs-tab[data-active]) {
  background: rgba(80, 60, 150, 0.4);
  position: relative;
  box-shadow: 0 0 20px rgba(150, 120, 255, 0.6), inset 0 0 10px rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(180, 160, 255, 0.4);
  border-radius: 12px;
  animation: cosmicPulse 3s infinite ease-in-out;
  transform: translateY(-3px) scale(1.05);
  transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.tab:hover {
  transform: translateY(-2px);
  background: rgba(80, 60, 150, 0.3);
  box-shadow: 0 0 15px rgba(150, 120, 255, 0.4);
}

.viewLink {
  background: rgba(74, 55, 183, 0.9);
  padding: 8px 16px;
  border-radius: 8px;
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
  box-shadow: 0 0 10px rgba(150, 120, 255, 0.4);
  border: 1px solid rgba(180, 160, 255, 0.1);
  position: relative;
  overflow: hidden;
}

.viewLink:hover {
  background: rgba(40, 30, 100, 0.5);
  box-shadow: 0 0 15px rgba(150, 120, 255, 0.4);
}

@media (max-width: 768px) {
  .contentWrapper {
    padding: 16px 20px;
    min-height: 500px;
  }

  .durationGroup {
    flex-direction: column;
    align-items: baseline;
  }

  .subtitle {
    text-shadow: none;
    margin-left: -5px;
  }

  .title {
    text-shadow: none;
  }

  .tab {
    height: 60px;
    width: auto;
    min-width: 120px;
    margin: 4px;
  }

  .tabs :global(.mantine-Tabs-panel) {
    padding: 10px 5px 30px;
  }

  .panel {
    padding: 15px 10px;
  }

  .description {
    font-size: 0.9rem;
  }

  .date {
    font-size: 0.8rem;
    background: none;
    padding: 0;
  }

  .viewLink {
    padding: 3px 8px;
    font-size: 0.9rem;
  }
}

@media (max-width: 464px) {
  .container {
    width: 100%;
  }

  .contentWrapper {
    padding: 16px 8px;
    min-height: 600px;
  }

  .tabs :global(.mantine-Tabs-list) {
    padding-right: 0;
    padding-bottom: 16px;
    border-right: none;
    display: flex;
    justify-content: space-evenly;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 16px;
  }

  .tab {
    height: 60px;
    width: 100px;
    min-width: 100px;
    margin: 4px;
  }

  /* Add swipe indicator for mobile */
  .contentWrapper::after {
    content: '< swipe >';
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.8rem;
    animation: fadeInOut 2s infinite;
    opacity: 0.7;
  }

  .tabs :global(.mantine-Tabs-panel) {
    transition: transform 0.3s ease-out;
  }
}

/*
 * Animations
 */

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
    filter: blur(5px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
    filter: blur(0);
  }
}

@keyframes borderRotate {
  0% {
    clip-path: inset(0 0 95% 0);
  }

  25% {
    clip-path: inset(0 0 0 95%);
  }

  50% {
    clip-path: inset(95% 0 0 0);
  }

  75% {
    clip-path: inset(0 95% 0 0);
  }

  100% {
    clip-path: inset(0 0 95% 0);
  }
}

@keyframes cosmicEntrance {
  0% {
    opacity: 0;
    transform: scale(0.8) translateY(-20px);
    text-shadow: 0 0 0 rgba(150, 120, 255, 0);
  }

  50% {
    text-shadow: 0 0 30px rgba(150, 120, 255, 0.9);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
    text-shadow: 0 0 15px rgba(150, 120, 255, 0.7);
  }
}

@keyframes cosmicPulse {
  0% {
    box-shadow: 0 0 20px rgba(150, 120, 255, 0.6), inset 0 0 10px rgba(255, 255, 255, 0.2);
    border-color: rgba(180, 160, 255, 0.4);
  }

  50% {
    box-shadow: 0 0 30px rgba(180, 150, 255, 0.8), inset 0 0 15px rgba(255, 255, 255, 0.3);
    border-color: rgba(200, 180, 255, 0.6);
  }

  100% {
    box-shadow: 0 0 20px rgba(150, 120, 255, 0.6), inset 0 0 10px rgba(255, 255, 255, 0.2);
    border-color: rgba(180, 160, 255, 0.4);
  }
}

@keyframes trailEffect {
  0% {
    opacity: 0.8;
    transform: scale(0.2);
  }

  100% {
    opacity: 0;
    transform: scale(2);
  }
}

/* Add this animation for the swipe indicator */
@keyframes fadeInOut {

  0%,
  100% {
    opacity: 0.3;
  }

  50% {
    opacity: 0.7;
  }
}