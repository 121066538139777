.heading {
  margin-bottom: 40px;
  font-size: 4rem;
  font-weight: 900;
  color: var(--mantine-color-white);
  text-transform: uppercase;
  letter-spacing: 4px;
  line-height: 4rem;
  text-shadow: 0 0 15px rgba(150, 120, 255, 0.7);
}

.pageContainer {
  margin-top: 0px;
  width: 85%;
  max-width: 1200px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 70vh;
}

@media (max-width: 768px) {
  .heading {
    font-size: 2.5rem;
    line-height: 3rem;
    text-align: center;
  }

  .pageContainer {
    width: unset;
    max-width: 95%;
    margin-top: 2vh;
    padding: 0px 0px 60px;
    min-height: 60vh;
  }
}