.contactContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 70vh;
  width: 100%;
  position: relative;
}

.contactForm {
  font-weight: 700;
  width: 100%;
  max-width: 450px;
  position: relative;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  margin-bottom: 40px;
  height: 450px;
  /* Fixed height to prevent jumping */
  perspective: 1000px;
}

.contactForm form {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 0px;
  width: 100%;
}

.flipped {
  transform: rotateY(180deg);
}

.formSide,
.successSide {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  border-radius: 16px;
  padding: 20px;
  color: white;
  background: linear-gradient(45deg, rgba(40, 30, 100, 0.8), rgba(80, 60, 150, 0.7));
  backdrop-filter: blur(4px);
  box-shadow: 0 0 30px rgba(150, 120, 255, 0.4);
  border: 1px solid rgba(180, 160, 255, 0.2);
}

.formSide {
  transform: rotateY(0deg);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.successSide {
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.label {
  color: #fff;
  font-size: 1rem;
  margin-bottom: 5px;
  text-shadow: 1px 0px 5px rgba(0, 0, 0, 0.5);
  align-self: flex-start;
  text-align: left;
  width: 100%;
}

.input {
  border-radius: 5px;
  border: 0.5px solid var(--mantine-color-primary-9);
  color: #fff;
  width: 100%;
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
}

.error {
  color: #ff6b6b;
  font-size: 0.8rem;
  margin-top: -5px;
  margin-bottom: 10px;
  text-align: left;
  width: 100%;
}

.button {
  font-weight: 700;
  padding: 10px;
  width: 100%;
  background: linear-gradient(45deg, rgba(80, 60, 150, 0.8), rgba(40, 30, 100, 0.9));
  box-shadow: 0 0 20px rgba(150, 120, 255, 0.6), inset 0 0 10px rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(180, 160, 255, 0.4);
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 10px;
  height: 46px;
  border-radius: 6px;
}

.button:hover {
  transform: translateY(-3px);
  box-shadow: 0 0 30px rgba(180, 150, 255, 0.8);
}

/* Add constellation effect similar to Projects page */
.formSide::before,
.successSide::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image:
    radial-gradient(circle at 10% 20%, rgba(255, 255, 255, 0.3) 1px, transparent 1px),
    radial-gradient(circle at 30% 65%, rgba(255, 255, 255, 0.25) 1px, transparent 1px),
    radial-gradient(circle at 60% 10%, rgba(255, 255, 255, 0.2) 1px, transparent 1px),
    radial-gradient(circle at 80% 40%, rgba(255, 255, 255, 0.3) 1px, transparent 1px),
    radial-gradient(circle at 90% 90%, rgba(255, 255, 255, 0.25) 1px, transparent 1px);
  background-size: 300px 300px;
  opacity: 0.3;
  z-index: -1;
  pointer-events: none;
  border-radius: 16px;
}

@media (max-width: 768px) {
  .contactForm {
    /* Taller on mobile */
    height: 500px;
  }

  .formSide,
  .successSide {
    padding: 20px;
  }

  .button {
    margin-top: 15px;
  }
}

.heading {
  color: var(--mantine-color-white);
  font-size: 3.75rem;
  font-weight: 900;
  letter-spacing: 4px;
  line-height: 4rem;
  margin-bottom: 40px;
  text-shadow: 6px 4px 10px rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
}