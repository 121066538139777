.rocket {
  transition: opacity 0.3s ease;
  animation: floatAnimation 2s ease-in-out infinite;
}

.exhaustLine1 {
  opacity: 0;
  animation: exhaustVisibility1 2s ease-in-out infinite;
}

.exhaustLine2 {
  opacity: 0;
  animation: exhaustVisibility2 2s ease-in-out infinite;
}

.exhaustLine1.visible,
.exhaustLine2.visible {
  animation: flyOut 2s ease-in-out infinite;
}

.flyingIn {
  animation: flyIn 1s ease-out forwards;
}

.flyingOut {
  animation: flyOut 1s ease-out forwards;
}

@keyframes floatAnimation {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

@keyframes exhaustVisibility1 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes exhaustVisibility2 {
  0% {
    opacity: 0;
  }

  25%,
  75% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes flyOut {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-500px);
    opacity: 0;
  }
}

@keyframes flyIn {
  0% {
    transform: translateY(500px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}