.footer {
  width: 100%;
  margin-top: auto;
  color: white;
  text-align: center;
  padding: 20px 0;
}

.backgroundContainer {
  background-image: url(../../../public/images/space-wallpaper.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: visible;
  padding: 0px;
  width: 100%;
}

@media (max-width: 768px) {
  .backgroundContainer {
    min-height: 100vh;
    background-attachment: scroll;
    background-position: center;
    -webkit-overflow-scrolling: touch;
  }

  .header {
    padding: 20px 0px 0px;
  }
}