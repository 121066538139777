/*
* Animations
*/
@keyframes spinAndGrow {
  0% {
    transform: scale(0) rotate(0deg);
  }

  100% {
    transform: scale(1) rotate(1080deg);
  }
}

@keyframes continuousSpin {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
}

@keyframes shadowRotate {
  0% {
    box-shadow:
      -15px 0px 25px rgba(255, 255, 255, 0.15),
      15px 0px 25px rgba(0, 0, 0, 0.4);
  }

  25% {
    box-shadow:
      0px -10px 20px rgba(255, 255, 255, 0.1),
      0px 15px 25px rgba(0, 0, 0, 0.4);
  }

  50% {
    box-shadow:
      15px 0px 25px rgba(255, 255, 255, 0.15),
      -15px 0px 25px rgba(0, 0, 0, 0.4);
  }

  75% {
    box-shadow:
      0px 15px 25px rgba(255, 255, 255, 0.15),
      0px -10px 20px rgba(0, 0, 0, 0.3);
  }

  100% {
    box-shadow:
      -15px 0px 25px rgba(255, 255, 255, 0.15),
      15px 0px 25px rgba(0, 0, 0, 0.4);
  }
}

@keyframes cosmicEntrance {
  0% {
    opacity: 0;
    transform: scale(0.8) translateY(-20px);
    text-shadow: 0 0 0 rgba(150, 120, 255, 0);
  }

  50% {
    text-shadow: 0 0 30px rgba(150, 120, 255, 0.9);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
    text-shadow: 0 0 15px rgba(150, 120, 255, 0.7);
  }
}

@keyframes floatAnimation {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

@keyframes orbitGlow {

  0%,
  100% {
    box-shadow:
      inset 5px -5px 25px rgba(255, 255, 255, 0.2),
      inset -5px 5px 25px rgba(0, 0, 0, 0.4),
      0 0 40px rgba(150, 120, 255, 0.5);
  }

  50% {
    box-shadow:
      inset 5px -5px 25px rgba(255, 255, 255, 0.2),
      inset -5px 5px 25px rgba(0, 0, 0, 0.4),
      0 0 60px rgba(180, 150, 255, 0.7);
  }
}

.avatar {
  width: 500px;
  height: 500px;
  transition: transform 0.5s ease;
  transform-style: preserve-3d;
  perspective: 1000px;
  touch-action: pan-y pinch-zoom;
}

.avatar:hover .front,
.avatar:hover .back {
  animation: orbitGlow 3s infinite ease-in-out;
}

.front,
.back {
  width: 100%;
  max-width: 600px;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  backface-visibility: hidden;
  transform: rotateY(0deg);
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center;
  box-shadow:
    inset 5px -5px 25px rgba(255, 255, 255, 0.2),
    inset -5px 5px 25px rgba(0, 0, 0, 0.4),
    0 0 40px rgba(150, 120, 255, 0.5);
}

.back {
  transform: rotateY(180deg);
  background-color: #f0f0f0;
  /* Adjust background color for the back of the card */
}

.portraitWrapper {
  display: flex;
  justify-content: center;
}

.button {
  background: linear-gradient(45deg, rgba(80, 60, 150, 0.8), rgba(40, 30, 100, 0.9));
  box-shadow: 0 0 20px rgba(150, 120, 255, 0.6), inset 0 0 10px rgba(255, 255, 255, 0.2);
  font-weight: 700;
  width: 80%;
  align-self: center;
  border: 1px solid rgba(180, 160, 255, 0.4);
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  animation: floatAnimation 4s infinite ease-in-out;
  animation-delay: 0.5s;
  height: 70px;
}

.button:hover {
  transform: translateY(-3px);
  box-shadow: 0 0 30px rgba(180, 150, 255, 0.8);
}

/* Make the second button float out of sync */
.button:nth-child(3) {
  animation-delay: 1.5s;
}

.name {
  margin-top: 20px;
  color: white;
  text-shadow: 0 0 10px rgba(150, 120, 255, 0.7);
  font-weight: 600;
  letter-spacing: 1px;
}

.subtext {
  color: white;
  text-shadow: 0 0 8px rgba(150, 120, 255, 0.6);
  letter-spacing: 0.5px;
  opacity: 0.9;
  margin-top: 5px;
}

.greeting {
  margin-bottom: 40px;
  font-size: 4rem;
  font-weight: 900;
  color: var(--mantine-color-white);
  text-transform: uppercase;
  letter-spacing: 4px;
  line-height: 4rem;
  text-shadow: 0 0 15px rgba(150, 120, 255, 0.7);
  animation: cosmicEntrance 1.2s ease-out;
}

.leftColumn {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  height: 100%;
  padding: 20px;
}

.rightColumn {
  background-color: rgba(20, 15, 50, 0.6);
  backdrop-filter: blur(4px);
  margin: 30px;
  padding: 30px;
  border-radius: 16px;
  text-align: center;
  box-shadow: 0 2px 20px rgba(150, 120, 255, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
  animation: floatAnimation 6s infinite ease-in-out;
}

/* On hover, rotate the avatar to reveal the back image */
@media (min-width: 768px) {
  .avatar:hover {
    transform: rotateY(180deg) !important;
  }

  .portraitWrapper:hover .avatar {
    transform: rotateY(180deg);
  }
}

@media (max-width: 1200px) {
  .avatar {
    width: 400px;
    height: 400px;
  }

  .front,
  .back {
    max-width: 400px;
  }

  .greeting {
    animation: none;
  }
}

/* Mobile */
@media (max-width: 767px) {
  .greeting {
    text-align: center;
    font-size: 2.5rem;
    letter-spacing: 2px;
    line-height: 2.8rem;
    text-shadow: 0 0 15px rgba(150, 120, 255, 0.7);
    margin-bottom: 25px;
  }

  .container {
    margin: 0px 10px;
  }

  .button {
    width: 100%;
  }

  .avatar {
    max-width: 280px;
    height: 280px;
    margin: 0 auto 20px;
  }

  .rightColumn {
    margin: 30px 10px;
    box-shadow: 0 0px 4px rgba(150, 120, 255, 0.3)
  }

  .avatar:hover {
    transform: none;
  }

  .avatar {
    max-width: 250px;
    height: 250px;
    animation: continuousSpin 5s linear infinite;
    transform: none;
  }

  .front,
  .back {
    animation: shadowRotate 5s linear infinite;
  }

}