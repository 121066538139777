.navText {
  cursor: pointer;
  transition: all 0.5s ease;

  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.75);
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.2rem;
}

.active {
  font-weight: 800;
  font-size: 1.55rem;
  text-shadow: 0 0 15px rgba(150, 120, 255, 0.7);
}